.container {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  max-width: 1000px;
}

h1 {
  font-family: 'Rampart One', cursive;
  font-size: 34px;
  font-weight: 400;
  margin-top: 10px;
  padding: 20px 0;
}

h2 {
  font-family: 'Lobster', cursive;
  font-weight: 400;
  font-size: 24px;
  padding: 5px 0;
}

p {
  font-size: 14px;
  padding: 5px 0;
}

button {
  font-family: inherit;
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  background-color: #E8F6EF;
  border: 1px solid #000;
  border-radius: 5px;
  transition: all 0.2s ease-out;
}

button:hover {
  background-color: black;
  color: white;
}

.day5 h2 span {
  font-family: 'Rampart One', cursive;
  font-size: 60px;
  font-weight: 800;
  background-image: linear-gradient(130deg,
    violet, indigo, blue, green, yellow, orange, red,
    red, orange, yellow, green, blue, indigo, violet
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: red;
  background-size: 200% 100%;
  animation: wowDoubleRainbow 1s linear infinite;
}

@keyframes wowDoubleRainbow {
  0% { background-position: 0 0; }
  100% { background-position: 200% 0; }
}

.youtube-wrapper {
  position: relative;
  padding-top: 56.25%;
  height: 0;
}

.youtube-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 4px;
  color: black;
  background-color: white;
  font-size: 12px;
}

.nowplaying {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 30px;
  font-size: 14px;
  background-color: black;
  color: white;
  overflow: hidden;
}

.nowplaying div {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: end;
  animation: marquee 10s linear infinite;
  white-space:nowrap;
}

.day5 .nowplaying div {
  animation: marquee 10s linear infinite, fridaymarquee 1s linear infinite;
}

@keyframes marquee {
  100% { left: -100%; }
}

@keyframes fridaymarquee {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}


@media (min-width: 640px) {
  h1 {
    font-size: 68px;
  }
  h2 {
    font-size: 32px;
  }
  p {
    font-size: 20px;
  }
  .nowplaying div {
    animation: marquee 40s linear infinite;
  }
  .day5 .nowplaying div {
    animation: marquee 10s linear infinite, fridaymarquee 1s linear infinite;
  }
}
